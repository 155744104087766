import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthStore } from "@app/core/auth";
import { environment } from "@environments/environment";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private auth: AuthStore) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (this.auth.isAuthenticated() && isApiUrl) {
      request = request.clone({
        setHeaders: {
            Authorization: `Bearer ${this.auth.getAccessToken()
          }`
        },
      });
    }

    return next.handle(request);
  }
}
